import {
  StringSetting,
  useUserSettingWithDefault,
} from "@airmont/shared/ts/utils/user-settings";
import { NavigationType } from "@airmont/shared/ts/ui/navigation";
import { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import { SizeClass, useWindowWidth } from "@airmont/shared/ts/ui/responsive";

export const useNavigationType = (
  forcedNavigationType?: NavigationType
): {
  navigationType: NavigationType;
  navigationTypeByWidth: NavigationType;
  navigationTypeByUser: NavigationType | null;
  setNavigationTypeByUser: Dispatch<SetStateAction<string | null>>;
  toggleNavigationType: () => void;
} => {
  const windowWidth = useWindowWidth();
  const navigationTypeByWidth =
    forcedNavigationType != null
      ? forcedNavigationType
      : resolveNavigationType(windowWidth);

  const [navigationTypeByUser, setNavigationTypeByUser] =
    useUserSettingWithDefault<string | null>(
      "AppLayout.navigationType",
      StringSetting,
      null,
      {
        storeLocally: true,
      }
    );

  const navigationType =
    navigationTypeByUser != null && navigationTypeByWidth !== "bar"
      ? (navigationTypeByUser as NavigationType)
      : navigationTypeByWidth;

  const handleToggleNavigationTypeByUser = useCallback(() => {
    //setHovering(false);
    if (navigationTypeByUser == null && navigationTypeByWidth === "drawer") {
      setNavigationTypeByUser("rail");
    } else if (
      navigationTypeByUser == null &&
      navigationTypeByWidth === "rail"
    ) {
      setNavigationTypeByUser("drawer");
    } else if (navigationTypeByUser === "drawer") {
      setNavigationTypeByUser("rail");
    } else if (
      navigationTypeByWidth === "rail" &&
      navigationTypeByUser === "rail"
    ) {
      setNavigationTypeByUser("drawer");
    } else if (
      navigationTypeByUser === "rail" &&
      navigationTypeByWidth === "drawer"
    ) {
      setNavigationTypeByUser(null);
    }
  }, [navigationTypeByUser, navigationTypeByWidth, setNavigationTypeByUser]);

  return useMemo(() => {
    return {
      navigationType: navigationType,
      navigationTypeByWidth: navigationTypeByWidth,
      navigationTypeByUser: navigationTypeByUser as NavigationType | null,
      setNavigationTypeByUser: setNavigationTypeByUser,
      toggleNavigationType: handleToggleNavigationTypeByUser,
    };
  }, [
    navigationType,
    navigationTypeByWidth,
    navigationTypeByUser,
    setNavigationTypeByUser,
    handleToggleNavigationTypeByUser,
  ]);
};

const resolveNavigationType = (breakpoint: SizeClass): NavigationType => {
  if (breakpoint === SizeClass.Compact) {
    return "bar";
  } else if (breakpoint === SizeClass.Medium) {
    return "rail";
  } else if (breakpoint === SizeClass.Expanded) {
    return "rail";
  } else if (breakpoint === SizeClass.ExtraLarge) {
    return "drawer";
  }

  return "bar";
};
