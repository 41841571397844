import { NavigationItemObject } from "@airmont/shared/ts/ui/navigation";
import { useMemo } from "react";
import {
  ActionGroup,
  isActionGroup,
  isRouteItem,
} from "@airmont/shared/ts/ui/action";

export const useFilterNavigationItems = (
  navigationItems: Array<NavigationItemObject>,
  isModal: boolean
): Array<NavigationItemObject> => {
  return useMemo(() => {
    if (isModal) {
      return filterModalNavigationItems(navigationItems);
    }

    return navigationItems;
  }, [navigationItems, isModal]);
};

const filterModalNavigationItems = (
  navigationItems: Array<NavigationItemObject>
): Array<NavigationItemObject> => {
  const result: Array<NavigationItemObject> = [];
  navigationItems.forEach((navigationItem) => {
    if (isAllowedModalNavigationItem(navigationItem)) {
      if (navigationItem !== undefined && isActionGroup(navigationItem)) {
        const childItems = navigationItem.items.filter(
          isAllowedModalNavigationItem
        );

        if (
          childItems.length > 0 &&
          childItems.length !== navigationItem.items.length
        ) {
          if (childItems.length === 1) {
            result.push(childItems[0]);
          } else {
            const modifiedGroup: ActionGroup = {
              ...navigationItem,
              items: childItems,
            };
            result.push(modifiedGroup);
          }
        }
      } else {
        result.push(navigationItem);
      }
    }
  });

  return result;
};

const isAllowedModalNavigationItem = (
  navigationItem: NavigationItemObject
): boolean => {
  if (navigationItem === undefined) {
    return false;
  }
  if (isRouteItem(navigationItem)) {
    return false;
  }
  if (isActionGroup(navigationItem)) {
    return true;
  }
  return true;
};
