import { NavigationItemObject } from "./shared/NavigationItemObject";
import {
  isActionGroup,
  isRouteItem,
  isSelectableAction,
  RouteItem,
  SelectableAction,
  SelectableItem,
} from "@airmont/shared/ts/ui/action";
import React, {
  DependencyList,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { SelectedRouteItemResolver } from "./SelectedRouteItemResolver";
import { NavigationType } from "./NavigationType";

const noDeps: DependencyList = [];

const updateSelectedRoute = (
  items: Array<NavigationItemObject>,
  selected: RouteItem | undefined
): Array<NavigationItemObject> => {
  return items.map((it) => {
    if (it === undefined) {
      return it;
    } else if (isRouteItem(it)) {
      if (selected !== undefined) {
        if (selected === it || selected.route === it.route) {
          return { ...it, selected: true };
        } else {
          return it.selected ? { ...it, selected: false } : it;
        }
      } else {
        return it.selected ? { ...it, selected: false } : it;
      }
    } else if (isActionGroup(it)) {
      return {
        ...it,
        items: updateSelectedRoute(it.items, selected),
      } as NavigationItemObject;
    } else {
      return it;
    }
  });
};

const updateSelectedAction = (
  items: Array<NavigationItemObject>,
  selected: SelectableAction | undefined
): Array<NavigationItemObject> => {
  return items.map((it) => {
    if (it === undefined) {
      return it;
    } else if (isSelectableAction(it)) {
      if (selected !== undefined) {
        if (selected === it || selected.name === it.name) {
          return { ...it, selected: true };
        } else {
          return it.selected ? { ...it, selected: false } : it;
        }
      } else {
        return it.selected ? { ...it, selected: false } : it;
      }
    } else if (isActionGroup(it)) {
      return {
        ...it,
        items: updateSelectedAction(it.items, selected),
      } as NavigationItemObject;
    } else {
      return it;
    }
  });
};

type UseNavigationReturnType = {
  navigationItems: Array<NavigationItemObject>;
  handleSelected: (item: SelectableItem) => void;
};
export const useNavigation = (
  initialItems: Array<NavigationItemObject>,
  deps: DependencyList,
  navigationType: NavigationType
): UseNavigationReturnType => {
  const resolvedDeps = deps ?? noDeps;
  const navigate = useNavigate();
  const location = useLocation();
  const [navigationItems, setNavigationItems] =
    useState<Array<NavigationItemObject>>(initialItems);
  const [selectedRouteItem, setSelectedRouteItem] = useState<
    RouteItem | undefined
  >(
    new SelectedRouteItemResolver().resolve(navigationItems, location.pathname)
  );

  useEffect(() => {
    const newSelectedRouteItem = new SelectedRouteItemResolver().resolve(
      navigationItems,
      location.pathname
    );
    if (newSelectedRouteItem?.route !== selectedRouteItem?.route) {
      setSelectedRouteItem(newSelectedRouteItem);
    }
  }, [location.pathname, navigationItems]);

  useEffect(() => {
    setNavigationItems(updateSelectedRoute(initialItems, selectedRouteItem));
  }, [...resolvedDeps]);

  useEffect(() => {
    setNavigationItems(updateSelectedRoute(navigationItems, selectedRouteItem));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRouteItem]);

  const handleSelect = useCallback(
    (newSelectedItem: SelectableItem) => {
      if (isRouteItem(newSelectedItem)) {
        setSelectedRouteItem(newSelectedItem);
        navigate(newSelectedItem.route);
      } else if (isSelectableAction(newSelectedItem)) {
        setNavigationItems(
          updateSelectedAction(navigationItems, newSelectedItem)
        );
      }
    },
    [navigationItems, navigate]
  );

  const filteredNavigationItems = useMemo(() => {
    if (navigationType === "rail") {
      return navigationItems.filter((it) => !React.isValidElement(it));
    }
    return navigationItems;
  }, [navigationItems, navigationType]);

  return useMemo(
    () => ({
      navigationItems: filteredNavigationItems,
      handleSelected: handleSelect,
    }),
    [filteredNavigationItems, handleSelect]
  );
};
