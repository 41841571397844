import { FetchHelper } from "@airmont/shared/ts/utils/fetch";
import { CustomerEnvironmentName } from "@airmont/firefly/shared/ts/customer-environment";
import {
  _throw,
  IllegalStateError,
  NullError,
} from "@airmont/shared/ts/utils/core";
import { DateTime } from "luxon";
import { TimeSeriesQuery } from "./TimeSeriesQuery";
import { TimeSeriesDto } from "@airmont/firefly/shared/ts/timeseries";

interface DateResponse {
  value: string;
}

export class TimeSeriesDao {
  public readonly created: Date;
  private readonly fetchHelper: FetchHelper;

  constructor(args: {
    accessToken: string;
    customerEnvironment: CustomerEnvironmentName;
  }) {
    this.created = new Date();
    this.fetchHelper = new FetchHelper({ basePath: "/api/timeseries/" })
      .authorizationBearer(args.accessToken)
      .addHeader(
        "Firefly-Environment",
        args.customerEnvironment ?? _throw(new NullError("customerEnvironment"))
      );
  }

  async query<DP>(
    query: TimeSeriesQuery | Array<TimeSeriesQuery>
  ): Promise<Array<TimeSeriesDto<DP>>> {
    if (Array.isArray(query)) {
      return this.fetchHelper
        .post(
          "querylist",
          query.map((it) => it.toDto())
        )
        .fetchAsJson();
    }

    return this.fetchHelper.post("querylist", [query.toDto()]).fetchAsJson();
  }

  async getChimneyFireTemperatureData(
    id: string,
    options?: { refresh?: boolean; previousTime?: DateTime }
  ): Promise<TimeSeriesDto<number> | null> {
    const params = new URLSearchParams();
    if (options?.refresh != null) {
      params.append("refresh", options?.refresh?.toString());
    }
    if (options?.previousTime != null) {
      params.append(
        "from",
        options?.previousTime?.plus({ second: 1 }).toUTC().toISO() ??
          _throw(
            new IllegalStateError(
              "previousTime is invalid: " + JSON.stringify(options.previousTime)
            )
          )
      );
    }
    const path = `get-chimney-fire-temperature-data/${id}`;
    return await this.fetchHelper
      .get(path, params)
      .fetchAsJson<TimeSeriesDto<number>>();
  }

  async getActiveChimneyFireTime(
    id: string
  ): Promise<DateTime<boolean> | null> {
    const path = `get-active-chimney-fire-time/${id}`;
    const response = await this.fetchHelper
      .get(path)
      .fetchAsJson<DateResponse>();
    console.log("Backend breach time", response);
    if (response.value) {
      const luxonDate = DateTime.fromISO(response.value);
      console.log("Finished converting", response.value, luxonDate);
      return luxonDate;
    }
    return null;
  }
}
