import React, {
  CSSProperties,
  FC,
  ReactElement,
  ReactNode,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  ImperativePanelHandle,
  Panel,
  PanelGroup,
  PanelGroupStorage,
} from "react-resizable-panels";
import { Divider, Paper, SvgIconProps, useTheme } from "@mui/material";
import { merge } from "lodash";
import { SxProps } from "@mui/system";
import { usePaperElevation } from "@airmont/shared/ts/ui/elevation";
import { Variant } from "../Variant";
import { MainPaneProps } from "../shared/MainPaneProps";
import { SupportingPaneProvider } from "../shared/SupportingPaneProvider";
import { SupportingPaneProps } from "../shared/SupportingPaneProps";
import { PageHeader } from "../shared/PageHeader";
import { ResizeHandle } from "../shared/ResizeHandle";
import { SupportingPaneHeader } from "../shared/SupportingPaneHeader";
import { SupportingPane } from "../shared/SupportingPane";
import { notUndef } from "@airmont/shared/ts/utils/core";
import { useResolveResizePanelProps } from "../shared/useResolveResizePanelProps";
import { supportingPaneDefaultSize } from "../shared/supportingPaneDefaultSize";

export interface AppPagePanelGroupVProps {
  name: string;
  width?: number;
  height?: number;
  variant?: Variant;
  hideName?: boolean;
  icon?: React.ComponentType<SvgIconProps>;
  children: ReactElement;
  mainPaneProps?: MainPaneProps;
  mainPaneActions?: ReactNode;
  supportingPane?: ReactElement | SupportingPaneProvider;
  supportingPaneProps?: SupportingPaneProps;
  supportingPaneOpen: boolean;
  disableResize?: boolean;
  onSupportingPaneOpen: (open: boolean) => void;
  onPanelGroupStorage: PanelGroupStorage;
}

/**
 * main pane is on top of supporting pane
 */
export const AppPagePanelGroupV: FC<AppPagePanelGroupVProps> = (props) => {
  const { supportingPaneOpen } = props;

  const theme = useTheme();
  const variant = props.variant ?? "elevation";
  const disableResize = props.disableResize ?? false;
  const paperVariant = variant === "flat" ? "outlined" : variant;
  const defaultPaperElevation = usePaperElevation();
  const bottomPanelRef = useRef<ImperativePanelHandle>(null);
  const [supportingPaneToolbarContainer, setSupportingPaneToolbarContainer] =
    useState<Element | null>(null);
  const displayHeader = props.mainPaneActions != null || !props.hideName;
  const resizePanelProps = useResolveResizePanelProps(
    "vertical",
    props.supportingPaneProps?.resizePanelProps
  );

  const bottomPanelResizePanelStyle: CSSProperties = useMemo(
    () =>
      merge({}, resizePanelProps?.style, {
        /* Remove any maxWidth to allow pane to take full width  */
        display: "flex",
        flexDirection: "column",
        paddingTop: theme.spacing(0.1),
        paddingRight: theme.spacing(variant === "flat" ? 0 : 1),
        paddingLeft: theme.spacing(variant === "flat" ? 0 : 1),
        paddingBottom: theme.spacing(variant === "flat" ? 0 : 1),
      }),
    [resizePanelProps?.style, theme, variant]
  );
  const supportingPaneSx: SxProps = useMemo(
    () =>
      merge({}, props.supportingPaneProps?.sx, {
        flexGrow: 1,
        overflowX: "auto",
        p: 1,
      }),
    [props.supportingPaneProps?.sx]
  );

  const supportingPanelMinSizeInPercent = useMemo(
    () =>
      notUndef(props.height, (height) =>
        resizePanelProps?.minHeight != null
          ? (resizePanelProps?.minHeight / height) * 100
          : undefined
      ),
    [props.height, resizePanelProps?.minHeight]
  );

  const supportingPanelMaxSizeInPercent = useMemo(
    () =>
      notUndef(props.height, (height) =>
        resizePanelProps?.maxHeight != null
          ? (resizePanelProps?.maxHeight / height) * 100
          : undefined
      ),
    [props.height, resizePanelProps?.maxHeight]
  );

  const handleSupportingPaneToggleOpen = (open: boolean) => {
    if (open !== supportingPaneOpen) {
      const bottomPanel = bottomPanelRef.current;
      if (bottomPanel != null) {
        if (open) {
          bottomPanel.expand();
          bottomPanel.resize(supportingPanelMinSizeInPercent ?? 40);
        } else {
          bottomPanel.collapse();
          bottomPanel.resize(0);
        }
      }

      props.onSupportingPaneOpen?.(open);
    }
  };

  const handleSupportingPanelCollapse = () => {
    props.onSupportingPaneOpen(false);
  };

  const handleSupportingPanelExpand = () => {
    props.onSupportingPaneOpen(true);
  };

  const handleResizeHandleOpen = () => {
    const bottomPanel = bottomPanelRef.current;
    if (bottomPanel != null) {
      bottomPanel.expand();
      bottomPanel.resize(supportingPanelMinSizeInPercent ?? 40);
    }
    props.onSupportingPaneOpen(true);
  };

  const handleSupportingPaneToolbarContainerMount = (element: Element) => {
    setSupportingPaneToolbarContainer(element);
  };

  return (
    <PanelGroup
      direction={"vertical"}
      autoSaveId={"AppPage"}
      storage={props.onPanelGroupStorage}
    >
      <Panel
        id={`${props.name}-main`}
        order={1}
        style={{
          display: "flex",
          flexDirection: "column",
          paddingTop: variant === "flat" ? theme.spacing(0) : theme.spacing(1),
          paddingRight:
            variant === "flat" ? theme.spacing(0) : theme.spacing(1),
          paddingLeft: variant === "flat" ? theme.spacing(0) : theme.spacing(1),
          paddingBottom:
            variant === "flat"
              ? theme.spacing(0)
              : variant === "elevation"
              ? theme.spacing(0.1)
              : 0,
          borderBottom:
            variant === "flat"
              ? "1px solid " + theme.palette.divider
              : undefined,
        }}
      >
        <Paper
          variant={paperVariant}
          className={"MainPaneContainer MainPaneContainer-vertical"}
          elevation={
            paperVariant === "elevation"
              ? props.mainPaneProps?.paperElevation != null
                ? props.mainPaneProps?.paperElevation
                : defaultPaperElevation
              : undefined
          }
          sx={{
            flex: 1,
            minHeight: 0,
            minWidth: 0,
            display: "flex",
            flexDirection: "column",
            ...(variant === "flat" && {
              border: "unset",
              backgroundColor: "unset",
              boxShadow: "unset",
              ">:not(.MuiDivider-root):not(.PageHeader)": {
                p: props.mainPaneProps?.disableGutters === true ? undefined : 1,
              },
            }),
            ...(variant === "elevation" && {
              ">:not(.MuiDivider-root):not(.PageHeader)": {
                p: props.mainPaneProps?.disableGutters === true ? undefined : 1,
              },
            }),
            ...(variant === "outlined" && {
              ">:not(.MuiDivider-root):not(.PageHeader)": {
                p: props.mainPaneProps?.disableGutters === true ? undefined : 1,
              },
            }),
            ...(props.mainPaneProps?.scrollableContentVertically === true && {
              paddingBottom: 0,
              ">:not(.PageHeader)": {
                overflowY: "auto",
              },
            }),
          }}
        >
          {displayHeader && (
            <>
              <PageHeader
                variant={variant}
                title={props.name}
                titleProps={{
                  sx: {
                    ...(variant === "flat" && {
                      fontSize: "1.2rem",
                      fontWeight: 500,
                    }),
                  },
                }}
                icon={props.icon}
                hideTitle={props.hideName}
              >
                {props.mainPaneActions}
              </PageHeader>
              <Divider />
            </>
          )}
          {props.children}
        </Paper>
      </Panel>
      {props.supportingPane != null && (
        <>
          <ResizeHandle
            type={"regular"}
            orientation={"horizontal"}
            variant={variant}
            collapsed={!supportingPaneOpen}
            label={props.supportingPaneProps?.label}
            disabled={disableResize}
            style={{
              marginTop: 0,
              marginBottom: 0,
            }}
            onOpen={handleResizeHandleOpen}
          />
          <Panel
            id={`${props.name}-support`}
            ref={bottomPanelRef}
            order={2}
            collapsible={true}
            collapsedSize={0}
            defaultSize={supportingPaneOpen ? supportingPaneDefaultSize : 0}
            minSize={supportingPanelMinSizeInPercent}
            maxSize={supportingPanelMaxSizeInPercent}
            onCollapse={handleSupportingPanelCollapse}
            onExpand={handleSupportingPanelExpand}
            style={bottomPanelResizePanelStyle}
          >
            {props.supportingPane &&
              props.supportingPaneProps &&
              props.supportingPaneOpen && (
                <Paper
                  className={"SupportingPaneContainer"}
                  variant={paperVariant}
                  elevation={defaultPaperElevation}
                  sx={{
                    flex: 1,
                    minHeight: 0,
                    minWidth: 0,
                    display: "flex",
                    flexDirection: "column",
                    paddingTop: theme.spacing(0),
                    paddingLeft: theme.spacing(0),
                    paddingRight: theme.spacing(0),
                    paddingBottom: theme.spacing(0),
                    ...(variant === "flat" && {
                      border: "unset",
                      borderTop: "1px solid " + theme.palette.divider,
                      borderTopLeftRadius: 0,
                      borderTopRightRadius: 0,
                      backgroundColor: "unset",
                      boxShadow: "unset",
                    }),
                  }}
                >
                  <SupportingPaneHeader
                    layout={"vertical"}
                    label={props.supportingPaneProps?.label}
                    disableResize={disableResize}
                    onToolbarContainerMount={
                      handleSupportingPaneToolbarContainerMount
                    }
                    onChange={handleSupportingPaneToggleOpen}
                  />
                  <Divider />
                  <SupportingPane
                    sx={supportingPaneSx}
                    open={props.supportingPaneOpen}
                    onToggleOpen={handleSupportingPaneToggleOpen}
                  >
                    {typeof props.supportingPane === "function"
                      ? supportingPaneToolbarContainer != null
                        ? props.supportingPane({
                            toolbarContainer: supportingPaneToolbarContainer,
                            layout: "PanelGroupVertical",
                          })
                        : null
                      : props.supportingPane}
                  </SupportingPane>
                </Paper>
              )}
          </Panel>
        </>
      )}
    </PanelGroup>
  );
};
