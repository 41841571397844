import React, { FC, useEffect, useState } from "react";
import { Alert, AlertTitle, Box, Container, Stack } from "@mui/material";
import { ValueCard } from "@airmont/firefly/shared/ts/ui";
import { DateTime } from "luxon";
import { TabPanel, TabPanelProps } from "@airmont/shared/ts/ui/tabs";
import {
  SensorId,
  useGetLatestChimneySensorSettingsBySensorIdOrUndefined,
} from "@airmont/firefly/shared/ts/domain";
import { notNullOrUndefined } from "@airmont/shared/ts/utils/core";
import {
  ChimneyFireChart,
  ChimneyFireUtils,
  FlueStatusDto,
} from "@airmont/firefly/chimney-insights/ts/domain";
import { useTranslation } from "react-i18next";

export interface ChimneyFireTabPanelProps extends TabPanelProps {
  id: SensorId;
  flueStatus: FlueStatusDto;
}

export const ChimneyFireTabPanel: FC<ChimneyFireTabPanelProps> = (props) => {
  const [activeFire, setActiveFire] = useState<boolean | undefined>(false);
  const { t: tFireflyDomain } = useTranslation("firefly-shared-ts-domain");
  const lastChimneyFireAsDateTime = notNullOrUndefined(
    props.flueStatus.flue.lastChimneyFire,
    (it) => DateTime.fromISO(it)
  );

  const latestChimneySensorSettingsEntityResponse =
    useGetLatestChimneySensorSettingsBySensorIdOrUndefined(props.id);

  const chimneyFireThreshold =
    latestChimneySensorSettingsEntityResponse?.entity?.settings
      .chimneyFireThreshold ?? props.flueStatus.flue.chimneyFireThreshold;

  useEffect(() => {
    setActiveFire(
      lastChimneyFireAsDateTime != null
        ? ChimneyFireUtils.isActiveChimneyFire(lastChimneyFireAsDateTime)
        : false
    );
  }, [lastChimneyFireAsDateTime]);

  return (
    <TabPanel tabValue="temperature-alarm" sx={props.sx}>
      <Container
        maxWidth={"sm"}
        sx={{ display: "flex", flexDirection: "column" }}
      >
        <Stack
          direction={"column"}
          useFlexGap={true}
          gap={1}
          sx={{ flex: 1, minHeight: 0 }}
        >
          {!activeFire && (
            <Stack direction={"row"} useFlexGap gap={1}>
              <ValueCard
                elevation={4}
                label={tFireflyDomain("Temperature Alarms")}
                value={
                  props.flueStatus.metricValuesSinceSweep?.chimneyFireCount
                }
              />
              <ValueCard
                elevation={4}
                label={tFireflyDomain("Temperature Alarm Threshold")}
                value={chimneyFireThreshold}
                unit={"°C"}
              />
            </Stack>
          )}
          {activeFire && lastChimneyFireAsDateTime && (
            <Stack direction={"column"} sx={{ flexGrow: 1, minHeight: 0 }}>
              <Box>
                <Box>
                  <Alert severity={"warning"}>
                    <AlertTitle>
                      {tFireflyDomain("Possible Chimney Fire")}
                    </AlertTitle>
                    {tFireflyDomain(
                      "Threshold ({{threshold}}) for temperature alarm was breached",
                      {
                        threshold:
                          chimneyFireThreshold != null
                            ? chimneyFireThreshold.toString()
                            : "?",
                      }
                    )}{" "}
                    {lastChimneyFireAsDateTime.toLocaleString(
                      DateTime.DATETIME_SHORT
                    )}
                    .{" "}
                    {tFireflyDomain(
                      "Fetching new temperature values every minute"
                    )}
                    .
                  </Alert>
                </Box>
              </Box>
              <ChimneyFireChart
                id={props.id}
                date={lastChimneyFireAsDateTime}
                threshold={chimneyFireThreshold}
              />
            </Stack>
          )}
        </Stack>
      </Container>
    </TabPanel>
  );
};
