import React, { FC, useEffect, useMemo, useState } from "react";
import { Box, LinearProgress, useTheme } from "@mui/material";
import { DateTime } from "luxon";
import {
  NumberTimeSeriesImpl,
  TimeSeries,
} from "@airmont/firefly/shared/ts/timeseries";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";
import { merge } from "lodash";
import { IllegalStateError } from "@airmont/shared/ts/utils/core";
import { DateTimeISO } from "@airmont/shared/ts/types";
import {
  TimeSeriesChart,
  TimeSeriesChartOptions,
  TimeSeriesOption,
} from "@airmont/firefly/shared/ts/timeseries-apex-chart";
import {
  TimeSeriesDao,
  useTimeSeriesDao,
} from "@airmont/firefly/shared/ts/timeseries-dao";
import { useTranslation } from "react-i18next";

export interface ChimneyFireChartProps {
  id: string;
  date: DateTime;
  threshold?: number;
  sx?: SxProps<Theme>;
}

export const ChimneyFireChart: FC<ChimneyFireChartProps> = (props) => {
  const theme = useTheme();
  const [timeSeries, setTimeSeries] = useState<
    Array<TimeSeries<number>> | undefined
  >(undefined);
  const [lastTime, setLastTime] = useState<DateTime | undefined>(undefined);
  const [thresholdBreachTime, setThresholdBreachTime] = useState<DateTime>(
    props.date
  );
  const [chartMaxTemp, setChartMaxTemp] = useState<number>(600);
  const [refreshCount, setRefreshCount] = useState<number>(0);
  const [loading, setLoading] = useState(true);
  const [fetchId, setFetchId] = useState<string | undefined>(undefined);
  const timeSeriesDao = useTimeSeriesDao();
  const { t } = useTranslation("firefly-chimney-insights-ts-domain");

  const getMaxTempForSeries = (
    series: TimeSeries<number> | undefined
  ): number => {
    if (!series) return -1;
    let maxTemp = 0;
    series.points.forEach((point) => {
      if (point.value > maxTemp) {
        maxTemp = point.value;
      }
    });

    return Math.ceil(maxTemp / 100) * 100;
  };

  useEffect(() => {
    if (!props.id || props.id === fetchId) return;
    let timerId = -1;
    setLoading(true);
    setFetchId(props.id);

    const doInitialLoadAsync = async (
      dao: TimeSeriesDao,
      id: string,
      previousBreachTime: DateTime
    ) => {
      console.log(
        "Firing fetch for",
        dao.created.toISOString(),
        id,
        previousBreachTime
      );
      const breachTime = await dao.getActiveChimneyFireTime(id);
      console.log("Got breach time", breachTime?.toISO());
      if (
        breachTime &&
        (!previousBreachTime || breachTime > previousBreachTime)
      ) {
        console.log("Setting breach time", breachTime.toISO());
        setThresholdBreachTime(breachTime);
      }

      const initialTimeSeriesDto = await dao.getChimneyFireTemperatureData(id);
      if (initialTimeSeriesDto == null) {
        setLoading(false);
        return;
      }

      const initialTimeSeries =
        NumberTimeSeriesImpl.fromNumberTimeSeriesDto(initialTimeSeriesDto);

      setTimeSeries([initialTimeSeries]);

      const lastPoint = initialTimeSeries.lastPoint;
      if (lastPoint != null) {
        setLastTime(lastPoint.time);
      }

      const maxTemp = getMaxTempForSeries(initialTimeSeries);
      setChartMaxTemp(maxTemp);

      timerId = setInterval(() => {
        setRefreshCount((prevRefreshCount) => prevRefreshCount + 1);
      }, 60 * 1000) as unknown as number;
    };

    doInitialLoadAsync(timeSeriesDao, props.id, thresholdBreachTime).then(
      () => {
        setLoading(false);
      }
    );

    return () => {
      clearInterval(timerId);
    };
  }, [timeSeriesDao, props.id]);

  useEffect(() => {
    if (!lastTime) {
      return;
    }
    setLoading(true);

    const doRefresh = async (
      previousMaxTemp: number,
      previousTime: DateTime | undefined
    ) => {
      const newTimeSeriesDto =
        await timeSeriesDao.getChimneyFireTemperatureData(props.id, {
          refresh: true,
          previousTime: previousTime,
        });

      if (newTimeSeriesDto == null || newTimeSeriesDto.points.length === 0) {
        return;
      }
      const newTimeSeries =
        NumberTimeSeriesImpl.fromNumberTimeSeriesDto(newTimeSeriesDto);

      const lastPoint = newTimeSeries.lastPoint;
      if (lastPoint != null) {
        setLastTime(lastPoint.time);
      }

      const maxTemp = getMaxTempForSeries(newTimeSeries);

      if (maxTemp > previousMaxTemp) {
        setChartMaxTemp(maxTemp);
      }

      setTimeSeries((previous) => {
        if (previous == null) {
          throw new IllegalStateError("should not happen");
        }
        return [
          new NumberTimeSeriesImpl({
            info: previous[0].info,
            points: [...previous[0].points, ...newTimeSeries.points],
          }),
        ];
      });
      setLoading(false);
    };

    if (refreshCount > 0) {
      doRefresh(chartMaxTemp, lastTime).then(() => {
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [refreshCount, lastTime, props.id, timeSeriesDao]);

  const yAxisAnnotations: Array<YAxisAnnotations> = useMemo(() => {
    if (props.threshold == null) {
      return [];
    }

    const yAxisAnnotations: Array<YAxisAnnotations> = [];
    yAxisAnnotations.push({
      y: props.threshold,
      borderColor: theme.palette.warning.main,
      label: {
        position: "left",
        textAnchor: "start",
        borderColor: theme.palette.warning.main,
        style: {
          color: theme.palette.getContrastText(theme.palette.warning.dark),
          background: theme.palette.warning.dark,
          fontSize: `14px`,
        },
        text: `${t("Threshold value")}: ${props.threshold} °C`,
      },
    });
    return yAxisAnnotations;
  }, [theme, props.threshold, t]);

  const xAxisAnnotations: Array<XAxisAnnotations> = useMemo(() => {
    const annotations: Array<XAxisAnnotations> = [];

    console.log(
      "creating new x annotation for breach time",
      thresholdBreachTime.toISO()
    );
    let anchor = "end";
    const time = thresholdBreachTime.toUTC().toMillis();
    const min = timeSeries?.[0].points[0].time.toUTC().toMillis();
    const max = timeSeries?.[0].points[timeSeries?.[0].points.length - 1].time
      .toUTC()
      .toMillis();
    if (time - (min ?? 0) < (max ?? 0) - time) {
      anchor = "start";
    }

    annotations.push({
      x: time,
      borderColor: theme.palette.warning.main,
      label: {
        position: "bottom",
        textAnchor: anchor,
        orientation: "horizontal",
        offsetY: -20,
        borderColor: theme.palette.warning.main,
        style: {
          color: theme.palette.getContrastText(theme.palette.warning.dark),
          background: theme.palette.warning.dark,
          fontSize: `14px`,
        },
        text: `${t("Threshold breach")}: ${thresholdBreachTime.toLocaleString(
          DateTime.DATETIME_SHORT
        )}`,
      },
    });
    return annotations;
  }, [timeSeries, thresholdBreachTime, theme.palette, t]);

  const sx = useMemo(() => {
    return merge({}, props.sx, {
      flex: 1,
      minHeight: 0,
      position: "relative",
      display: "flex",
      flexDirection: "column",
    });
  }, [props.sx]);

  const timeLabel = useMemo(() => {
    return t("Time");
  }, [t]);

  return (
    <Box className={"ChimneyFireChart"} sx={sx}>
      {loading && (
        <LinearProgress
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
          }}
        />
      )}

      {timeSeries && (
        <TimeSeriesChart
          timeSeries={timeSeries}
          timeSeriesOptions={
            [
              {
                displayName: t("Temperature alarm"),
                type: "line",
                color: theme.palette.error.main,
              },
            ] as Array<TimeSeriesOption<number>>
          }
          options={
            {
              noData: {
                text: t("No data"),
              },
              xAxis: {
                type: "datetime",
                title: {
                  text: timeLabel,
                },
                crosshairs: {
                  show: true,
                },
              },
              yAxis: {
                title: {
                  text: t("Temperature"),
                },
                unit: "°C",
                max: chartMaxTemp,
              },
              tooltip: {
                x: {
                  formatter: (value) => {
                    const dateTime = DateTime.fromMillis(Number(value));
                    return `${timeLabel}: ${dateTime.toLocaleString(
                      DateTime.DATETIME_SHORT_WITH_SECONDS
                    )}`;
                  },
                },
              },
              annotations: {
                yaxis: yAxisAnnotations,
                xaxis: xAxisAnnotations,
              },
            } as TimeSeriesChartOptions<DateTimeISO>
          }
          sx={{ flex: 1, minHeight: 0 }}
        />
      )}
    </Box>
  );
};
