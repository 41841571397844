import React, {
  CSSProperties,
  FC,
  ReactElement,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Box,
  darken,
  Divider,
  Paper,
  SvgIconProps,
  useTheme,
} from "@mui/material";
import styled from "styled-components";
import {
  ImperativePanelHandle,
  Panel,
  PanelGroup,
  PanelGroupStorage,
} from "react-resizable-panels";
import { SxProps } from "@mui/system";
import { merge } from "lodash";
import { usePaperElevation } from "@airmont/shared/ts/ui/elevation";
import { SwipeableSupportingPaneHeader } from "./SwipeableSupportingPaneHeader";
import { Variant } from "../Variant";
import { MainPaneProps } from "../shared/MainPaneProps";
import { SupportingPaneProvider } from "../shared/SupportingPaneProvider";
import { SupportingPaneProps } from "../shared/SupportingPaneProps";
import { PageHeader } from "../shared/PageHeader";
import { SupportingPane } from "../shared/SupportingPane";
import { ResizeHandle } from "../shared/ResizeHandle";
import { notUndef } from "@airmont/shared/ts/utils/core";
import { useResolveResizePanelProps } from "../shared/useResolveResizePanelProps";
import { supportingPaneDefaultSize } from "../shared/supportingPaneDefaultSize";

export const StyledAppPageSwipeableBottomPane = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export interface AppPageSwipeableBottomPaneProps {
  name: string;
  width?: number;
  height?: number;
  variant?: Variant;
  hideName?: boolean;
  icon?: React.ComponentType<SvgIconProps>;
  mainPaneProps?: MainPaneProps;
  mainPaneActions?: ReactNode;
  children?: ReactNode;
  supportingPane?: ReactElement | SupportingPaneProvider;
  supportingPaneProps?: SupportingPaneProps;
  supportingPaneOpen: boolean;
  disableResize?: boolean;
  onSupportingPaneOpen: (open: boolean) => void;
  onPanelGroupStorage: PanelGroupStorage;
}

export const AppPageSwipeableBottomPane: FC<AppPageSwipeableBottomPaneProps> = (
  props
) => {
  console.log("AppPageSwipeableBottomPane: **************************");
  const { supportingPaneOpen } = props;
  const theme = useTheme();
  const variant = props.variant ?? "elevation";
  const disableResize = props.disableResize ?? false;
  const paperVariant = variant === "flat" ? "outlined" : variant;
  const defaultPaperElevation = usePaperElevation();
  const [supportingPaneToolbarContainer, setSupportingPaneToolbarContainer] =
    useState<Element | null>(null);
  const displayHeader = props.mainPaneActions != null || !props.hideName;
  const toolbarContainerRef = React.useRef<Element | null>(null);
  const bottomPanelRef = useRef<ImperativePanelHandle>(null);

  useEffect(() => {
    if (toolbarContainerRef.current != null) {
      setSupportingPaneToolbarContainer(toolbarContainerRef.current);
    }
  }, [toolbarContainerRef]);

  const resizePanelProps = useResolveResizePanelProps(
    "mobile",
    props.supportingPaneProps?.resizePanelProps
  );
  const supportingPanelResizePanelStyle: CSSProperties = useMemo(
    () =>
      merge({}, resizePanelProps?.style, {
        maxHeight: "unset",
        minHeight: "unset",
        paddingTop: theme.spacing(0.1),
        paddingRight: theme.spacing(variant === "flat" ? 0 : 1),
        paddingLeft: theme.spacing(variant === "flat" ? 0 : 1),
        paddingBottom: theme.spacing(variant === "flat" ? 0 : 1),
        display: "flex",
        flexDirection: "column",
      }),
    [resizePanelProps?.style, variant, theme]
  );
  const supportingPaneSx: SxProps = useMemo(
    () =>
      merge(props.supportingPaneProps?.sx, {
        flexGrow: 1,
        overflowY: "auto",
        p: 1,
      }),
    [props.supportingPaneProps?.sx]
  );

  const supportingPanelMinSizeInPercent = useMemo(
    () =>
      notUndef(props.height, (height) =>
        resizePanelProps?.minHeight != null
          ? (resizePanelProps?.minHeight / height) * 100
          : undefined
      ),
    [props.height, resizePanelProps?.minHeight]
  );

  const supportingPanelMaxSizeInPercent = useMemo(
    () =>
      notUndef(props.height, (height) =>
        resizePanelProps?.maxHeight != null
          ? (resizePanelProps?.maxHeight / height) * 100
          : undefined
      ),
    [props.height, resizePanelProps?.maxHeight]
  );

  const handleSupportingPaneToggleOpen = (open: boolean) => {
    if (open !== supportingPaneOpen) {
      const bottomPanel = bottomPanelRef.current;
      if (bottomPanel != null) {
        if (open) {
          bottomPanel.expand();
          bottomPanel.resize(supportingPanelMinSizeInPercent ?? 40);
        } else {
          bottomPanel.collapse();
          bottomPanel.resize(0);
        }
      }
      console.log(`AppPageSwipeableBottomPane: onSupportingPaneOpen(${open})`);
      props.onSupportingPaneOpen(open);
    }
  };

  const handleSupportingPanelCollapse = () => {
    console.log(`AppPageSwipeableBottomPane: onSupportingPaneOpen(${false})`);
    props.onSupportingPaneOpen(false);
  };

  const handleSupportingPanelExpand = () => {
    console.log(`AppPageSwipeableBottomPane: onSupportingPaneOpen(${true})`);
    props.onSupportingPaneOpen(true);
  };

  const handleResizeHandleOpen = () => {
    const bottomPanel = bottomPanelRef.current;
    if (bottomPanel != null) {
      bottomPanel.expand();
      bottomPanel.resize(supportingPanelMinSizeInPercent ?? 40);
    }
    console.log(`AppPageSwipeableBottomPane: onSupportingPaneOpen(${true})`);
    props.onSupportingPaneOpen(true);
  };

  const handleSupportingPaneToolbarContainerMount = (element: Element) => {
    setSupportingPaneToolbarContainer(element);
  };

  console.log(
    "AppPageSwipeableBottomPane supportingPaneOpen: " + props.supportingPaneOpen
  );
  console.log(
    "AppPageSwipeableBottomPane supportingPanelMinSizeInPercent: " +
      supportingPanelMinSizeInPercent
  );
  return (
    <PanelGroup
      direction={"vertical"}
      autoSaveId={"AppPage"}
      storage={props.onPanelGroupStorage}
      style={{
        backgroundColor: theme.palette.background.default,
      }}
    >
      <Panel
        style={{
          display: "flex",
          flexDirection: "column",
          paddingTop: variant === "flat" ? theme.spacing(0) : theme.spacing(1),
          paddingRight:
            variant === "flat" ? theme.spacing(0) : theme.spacing(1),
          paddingLeft: variant === "flat" ? theme.spacing(0) : theme.spacing(1),
          paddingBottom:
            variant === "flat"
              ? theme.spacing(0)
              : variant === "elevation"
              ? theme.spacing(0.1)
              : 0,
        }}
      >
        <Paper
          variant={paperVariant}
          className={
            "MainPaneContainer MainPaneContainer-swipeable-bottom-pane"
          }
          elevation={
            paperVariant === "elevation"
              ? props.mainPaneProps?.paperElevation != null
                ? props.mainPaneProps?.paperElevation
                : defaultPaperElevation
              : undefined
          }
          sx={{
            flex: 1,
            minHeight: 0,
            minWidth: 0,
            display: "flex",
            flexDirection: "column",
            ...(variant === "flat" && {
              border: "unset",
              backgroundColor: "unset",
              boxShadow: "unset",
              ">:not(.MuiDivider-root):not(.PageHeader)": {
                px:
                  props.mainPaneProps?.disableGutters === true ? undefined : 1,
                pt:
                  props.mainPaneProps?.disableGutters === true ? undefined : 1,
              },
            }),
            ...(variant === "elevation" && {
              ">:not(.MuiDivider-root):not(.PageHeader)": {
                p: props.mainPaneProps?.disableGutters === true ? undefined : 1,
              },
            }),
            ...(variant === "outlined" && {
              ">:not(.MuiDivider-root):not(.PageHeader)": {
                p: props.mainPaneProps?.disableGutters === true ? undefined : 1,
              },
            }),
            ...(props.mainPaneProps?.scrollableContentVertically === true && {
              paddingBottom: 0,
              ">:not(.PageHeader)": {
                overflowY: "auto",
              },
            }),
          }}
        >
          {displayHeader && (
            <>
              <PageHeader
                variant={variant}
                title={props.name}
                icon={props.icon}
                hideTitle={props.hideName}
              >
                {props.mainPaneActions}
              </PageHeader>
              <Divider />
            </>
          )}
          {props.children}
        </Paper>
      </Panel>
      {props.supportingPane != null && (
        <>
          <ResizeHandle
            type={"mobile"}
            orientation={"horizontal"}
            variant={variant}
            label={props.supportingPaneProps?.label}
            collapsed={!supportingPaneOpen}
            hideLabel={false}
            hideHandle={false}
            disabled={disableResize}
            style={{
              marginTop: 0,
              marginBottom: 0,
              marginLeft: "1px",
              backgroundColor: darken(theme.palette.background.paper, 0.01),
            }}
            onOpen={handleResizeHandleOpen}
          />
          <Panel
            id={`${props.name}-support`}
            ref={bottomPanelRef}
            order={2}
            collapsible={true}
            collapsedSize={0}
            defaultSize={supportingPaneOpen ? supportingPaneDefaultSize : 0}
            minSize={supportingPanelMinSizeInPercent}
            maxSize={supportingPanelMaxSizeInPercent}
            onCollapse={handleSupportingPanelCollapse}
            onExpand={handleSupportingPanelExpand}
            style={supportingPanelResizePanelStyle}
          >
            {props.supportingPane &&
              props.supportingPaneProps &&
              props.supportingPaneOpen && (
                <Box
                  className={"SupportingPaneContainer"}
                  sx={{
                    flexGrow: 1,
                    minHeight: 0,
                    minWidth: 0,
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "1px",
                    borderLeft: "1px solid " + theme.palette.divider,
                    borderRight: "1px solid " + theme.palette.divider,
                    paddingTop: theme.spacing(0),
                    paddingLeft: theme.spacing(0),
                    paddingRight: theme.spacing(0),
                    paddingBottom: theme.spacing(0),
                    ...(variant === "flat" && {
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                      borderTopRightRadius: 0,
                      backgroundColor: darken(
                        theme.palette.background.paper,
                        0.01
                      ),
                      boxShadow: "unset",
                    }),
                  }}
                >
                  <SwipeableSupportingPaneHeader
                    label={props.supportingPaneProps?.label ?? ""}
                    hideLabel={true}
                    disableResize={disableResize}
                    onToolbarContainerMount={
                      handleSupportingPaneToolbarContainerMount
                    }
                    onChange={handleSupportingPaneToggleOpen}
                  />
                  <SupportingPane
                    sx={supportingPaneSx}
                    open={props.supportingPaneOpen}
                    onToggleOpen={handleSupportingPaneToggleOpen}
                  >
                    {typeof props.supportingPane === "function"
                      ? supportingPaneToolbarContainer != null
                        ? props.supportingPane({
                            toolbarContainer: supportingPaneToolbarContainer,
                            layout: "SwipeableBottomPane",
                          })
                        : null
                      : props.supportingPane}
                  </SupportingPane>
                </Box>
              )}
          </Panel>
        </>
      )}
    </PanelGroup>
  );
};
