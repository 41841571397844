import { SelectableItem } from "@airmont/shared/ts/ui/action";
import { ReactNode, useCallback, useState } from "react";
import { Box, Divider, useTheme } from "@mui/material";
import { PaperElevation } from "@airmont/shared/ts/ui/elevation";
import { Variant } from "./Variant";
import { NavigationTypeButton } from "./NavigationTypeButton";
import {
  NavigationBar,
  NavigationDrawer,
  NavigationItemObject,
  NavigationRail,
  NavigationType,
} from "@airmont/shared/ts/ui/navigation";
import { AppPageVariantProvider } from "@airmont/shared/ts/ui/app-page";
import { useNavigationType } from "./useNavigationType";
import { useFilterNavigationItems } from "./useFilterNavigationItems";

export interface AppLayoutProps {
  navigationItems:
    | Array<NavigationItemObject>
    | ((navigationType: NavigationType) => Array<NavigationItemObject>);
  variant?: Variant;
  onNavigationItemSelected: (item: SelectableItem) => void;
  main: ReactNode;
  modal?: ReactNode;
  navigationType?: NavigationType;
  paperElevation?: number;
  disableUserControlledNavigationType?: boolean;
}

export const AppLayout = (props: AppLayoutProps) => {
  const { main, modal } = props;
  const theme = useTheme();
  const variant = props.variant ?? "elevation";
  const disableUserControlledNavigationType =
    props.disableUserControlledNavigationType ?? false;

  const {
    navigationType,
    navigationTypeByWidth,
    navigationTypeByUser,
    toggleNavigationType,
  } = useNavigationType(props.navigationType);

  const navigationItems = useFilterNavigationItems(
    typeof props.navigationItems === "function"
      ? props.navigationItems(navigationType)
      : props.navigationItems,
    modal != null
  );

  const [hovering, setHovering] = useState<boolean>(false);
  const flexDirection = navigationType === "bar" ? "column-reverse" : "row";
  const showNavigationTypeButton =
    !disableUserControlledNavigationType && navigationType !== "bar";

  const handleToggleNavigationTypeByUser = useCallback(() => {
    setHovering(false);
    toggleNavigationType();
  }, [toggleNavigationType]);

  const handleNavigationTypeButtonHover = useCallback((hovering: boolean) => {
    setHovering(hovering);
  }, []);

  return (
    <Box
      className={`AppLayout AppLayout-${navigationType}`}
      sx={{
        height: "100%",
        padding: 0,
        display: "flex",
        flexDirection: flexDirection,
        position: "relative",
      }}
    >
      <PaperElevation elevation={props.paperElevation ?? 1}>
        {navigationType === "bar" && (
          <NavigationBar
            items={navigationItems}
            variant={variant}
            className={"navigation"}
            onSelected={props.onNavigationItemSelected}
          />
        )}
        {navigationType === "rail" && (
          <NavigationRail
            items={navigationItems}
            variant={variant}
            className={"navigation"}
            sx={{ width: 80, margin: theme.spacing(1) }}
            onSelected={props.onNavigationItemSelected}
          />
        )}
        {navigationType === "drawer" && (
          <NavigationDrawer
            items={navigationItems}
            variant={variant}
            className={"navigation"}
            sx={{
              margin: 1,
              padding: 1,
              ...(variant === "flat" && {
                padding: 1,
              }),
            }}
            onSelected={props.onNavigationItemSelected}
          />
        )}
        {navigationType !== "bar" && (
          <Divider
            orientation={"vertical"}
            sx={{
              position: "relative",
              borderColor: hovering
                ? theme.palette.primary.dark
                : variant === "elevation"
                ? "transparent"
                : undefined,
            }}
          ></Divider>
        )}
        {showNavigationTypeButton && (
          <NavigationTypeButton
            navigationTypeByWidth={navigationTypeByWidth}
            navigationTypeByUser={navigationTypeByUser}
            variant={variant}
            onToggle={handleToggleNavigationTypeByUser}
            onHover={handleNavigationTypeButtonHover}
          />
        )}
        <Box
          className={"main"}
          sx={{
            flexGrow: 1,
            minWidth: 0,
            minHeight: 0,
            display: "flex",
            "> *": {
              flexGrow: 1,
              minWidth: 0,
              minHeight: 0,
            },
          }}
        >
          <AppPageVariantProvider value={variant}>
            {modal != null ? modal : main}
          </AppPageVariantProvider>
        </Box>
      </PaperElevation>
    </Box>
  );
};
