import { FC, ReactNode, useMemo } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { SxProps } from "@mui/system";
import { SizeClass } from "@airmont/shared/ts/ui/responsive";
import { merge } from "lodash";
import { Button_color } from "shared-ts-mui";
import { grey } from "@mui/material/colors";

export interface InfoPillProps {
  label: string;
  info: ReactNode;
  color?: Button_color;
  variant?: "normal" | "outlined" | "filled";
  layout?: "fieldset" | "row" | SizeClass;
  hideLabel?: boolean;
  sx?: SxProps;
}

export const InfoPill: FC<InfoPillProps> = (props) => {
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";
  const { label, info } = props;
  const color = props.color ?? (isDark ? grey["700"] : grey["400"]);
  const layout: "fieldset" | "row" =
    props.layout === "fieldset" ||
    props.layout === SizeClass.Compact ||
    props.layout === SizeClass.Medium
      ? "fieldset"
      : "row";
  const variant = props.variant ?? "outlined";
  const hideLabel =
    props.hideLabel != null
      ? props.hideLabel
      : props.layout === SizeClass.Compact;

  const sx = useMemo(() => {
    const sxFieldSet: SxProps = {
      position: "relative",
      marginTop: hideLabel ? 0 : "-8px",
      paddingTop: "8px",
      paddingLeft: "14px",
      paddingRight: "14px",
      display: "inline-flex",
      flexWrap: "nowrap",
      alignItems: "center",
      maxWidth: "100%",
      "> *:not(fieldset) ": {
        marginTop: "-8px",
      },
    };
    const sxRow: SxProps = {
      borderStyle: variant === "outlined" ? "solid" : undefined,
      borderWidth: variant === "outlined" ? "1px" : undefined,
      borderColor: "divider",
      borderRadius: "16px",
      paddingLeft: "14px",
      paddingRight: "14px",
      maxWidth: "100%",
      display: "inline-flex",
      flexWrap: "nowrap",
      alignItems: "center",
      gap: 1,
      ...(variant === "filled" && {
        backgroundColor: color,
      }),
    };
    return merge({}, layout === "fieldset" ? sxFieldSet : sxRow, props.sx);
  }, [props.sx, layout, variant, color]);

  const labelSx = useMemo(() => {
    const sxFieldSet: SxProps = {
      position: "absolute",
      top: "13.5px",
      left: "25px",
      color: "text.secondary",
      fontFamily: "Roboto",
      fontWeight: 400,
      fontSize: "16px",
      transformOrigin: "top left",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      letterSpacing: "0.2px",
    };

    const sxRow: SxProps = {
      color: "text.secondary",
      fontFamily: "Roboto",
      fontWeight: 400,
      fontSize: "1rem",
    };

    return layout === "fieldset" ? sxFieldSet : sxRow;
  }, [layout]);

  const fieldSetSx = useMemo(() => {
    const sxBase: SxProps = {
      top: "0px",
      left: "0px",
      right: "0px",
      bottom: "0px",
      paddingLeft: hideLabel ? 0 : "8px",
      paddingRight: hideLabel ? 0 : "8px",
      paddingTop: 0,
      paddingBottom: 0,
      display: "inline-flex",
      pointerEvents: "none",
    };

    const sxOutlined: SxProps = {
      ...sxBase,
      borderRadius: "16px",
      borderWidth: "1px",
      borderColor: "divider",
    };

    const sxNormal: SxProps = {
      ...sxBase,
      border: "none",
    };
    return variant === "outlined" ? sxOutlined : sxNormal;
  }, [variant]);

  return (
    <Box className={"InfoPill"} sx={sx}>
      {!hideLabel && (
        <Typography component={"label"} noWrap sx={labelSx}>
          {label}
        </Typography>
      )}
      {layout === "row" && info}
      {layout === "fieldset" && (
        <Box component={"fieldset"} sx={fieldSetSx}>
          {!hideLabel && (
            <Box
              component={"legend"}
              sx={{
                opacity: 0,
                fontSize: "16px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                paddingInlineStart: 0,
                paddingInlineEnd: 0,
                letterSpacing: "0.2px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {label}
            </Box>
          )}
          {(typeof info === "string" ||
            typeof info === "number" ||
            typeof info === "boolean") && (
            <Box
              component={"span"}
              sx={{
                transform: "translate(0,-4px)",
              }}
            >
              {info}
            </Box>
          )}
          {!(
            typeof info === "string" ||
            typeof info === "number" ||
            typeof info === "boolean"
          ) && (
            <Box
              sx={{
                transform: "translate(0,-4px)",
                flex: 1,
                display: "flex",
              }}
            >
              {info}
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};
